<template>
    <div
        :class="{
            'text-white': theme === 'transparent',
            'bg-transparent': theme === 'transparent',
            'text-gray-900': theme === 'white',
            'bg-white': theme === 'white',
        }"
        class="h-10 w-full px-4 absolute z-50 flex flex-row justify-between items-center">
        <span class="text-sm">Africas Talking</span>
        <div class="flex flex-row">
            <svg class="h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M2 22h20V2z"/></svg>
            <svg class="h-4 fill-current ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M15.67 4H14V2h-4v2H8.33C7.6 4 7 4.6 7 5.33v15.33C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V5.33C17 4.6 16.4 4 15.67 4z"/></svg>
        </div>
        <div
            v-if="notification"
            class="absolute left-0 top-0 bg-white py-2 px-4 shadow-md border-b flex flex-col border-gray-200 w-full z-50">
            <p class="text-gray-800 text-sm mb-2">{{ notification }}</p>
            <div class="flex justify-end">
                <button
                    @click="dismissNotification"
                    class="text-orange-500 hover:text-orange-600 font-semibold uppercase">Ok</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'NotificationBar',

    props: {
        theme: {
            type: String,
            default: 'transparent',
        },
    },

    computed: {
        ...mapState('simulator', [
            'notification',
        ]),
    },

    methods: {
        dismissNotification() {
            this.$store.commit('simulator/setNotification', '');
        },
    },
};
</script>
